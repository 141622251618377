


























































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { GuideCardEntityModel, GuideCardQueryModel } from '@/pages/sub-system/model/guide-card-entity';
import GuideCardService from '@/pages/sub-system/service/guide-card';
import GuideCardLinkDialog from './guide-card-link.vue';

@Component({
    components: {
        'guide-card-link-dialog': GuideCardLinkDialog
    }
})
export default class GuideCardListComponent extends TableDialogFormComponent<GuideCardEntityModel, GuideCardQueryModel> {
    GuideCardEntityModel = GuideCardEntityModel;
    created() {
        this.initTable({
            service: GuideCardService,
            queryModel: new GuideCardQueryModel(),
            tableColumns: GuideCardEntityModel.getTableColumns()
        });
        this.getList();
    }

    guideCardLinkClick(model: GuideCardEntityModel) {
        (this.$refs.guideCardLinkDialog as GuideCardLinkDialog).dialogOpen(model?.id);
    }

    downloadExcelClick() {
        // window.location.href = '/template/引导牌模板.xlsx';
        // window.location.href = `${BIBIZ_BASE_REQUEST_PATH}/api/parkingSystem/guideCard/export/template`;
        GuideCardService.downloadExcelTemplate();
    }

    importExcelChange(fileList) {
        if (fileList && fileList.length > 0) {
            this.startFullScreenLoading('正在导入引导牌...');
            return GuideCardService.importExcel(_.get(fileList, '[0].file')).then(res => {
                this.showMessageSuccess('导入引导牌成功');
            }).finally(() => {
                this.getList();
                this.stopFullScreenLoading();
            });
        }
    }
}

