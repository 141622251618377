























































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import GuideCardService from './service/guide-card';
import { GuideCardLinkQueryModel } from './model/guide-card-entity';

@Component
export default class GuideCardLinkDialog extends Mixins(TransformComponent, TableComponent) {
    guidCardId: string = null;

    created() {
        this.pageSize = null;
        this.isLoadMore = true;
        this.initTable({ listFunc: GuideCardService.unLinkQuery, queryModel: new GuideCardLinkQueryModel() });
    }

    dialogOpen(id: string) {
        this.guidCardId = id;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];
        this.getList().then(() => {
            GuideCardService.getLinkedFacility(this.guidCardId).then(res => {
                this.rightDataList = _.map(res, item => {
                    return {
                        id: item.id,
                        name: item.name
                    };
                });
                this.checkLeftListData();
            }).catch(err => {
                this.dialogVisible = false;
            });
        });
    }

    dialogOK() {
        return GuideCardService.saveLinkRelation(this.guidCardId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }
}
